import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DatePipe, CommonModule } from '@angular/common';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-code-large-image',
  standalone: true,
  imports: [NgbNavModule, CommonModule],
  templateUrl: './code-large-image.component.html',
  styleUrl: './code-large-image.component.scss'
})
export class CodeLargeImageComponent {
  activeTab = 1;
  constructor(
    private dialogRef: MatDialogRef<CodeLargeImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { 
    jsonResponse: any,
    imageUrl: string , 
    firstName: string, 
    createdAt?: string , 
    mobileNumber: string, 
    payload: [],
    BankName: string,
    Status: string,
    entry: any,
    Code: string
    moderationStatus: string}, private datePipe: DatePipe)
   {}
  
   formatDate(dateString: string | null): string {
    if (!dateString) {
      return 'No Date Provided'; 
    }
    const formattedDate = this.datePipe.transform(dateString, 'dd MMM, yyyy hh:mm a');
    return formattedDate || 'Invalid Date'; 
  }

  downloadImage() {
    const imgElement = document.querySelector('.large-image') as HTMLImageElement;
    if (!imgElement || !imgElement.src) {
      alert('Image element or source is missing');
      return;
    }
  
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
  
    const image = new Image();
    image.crossOrigin = 'Anonymous';
    image.src = imgElement.src;
  
    const scaleFactor = 8; 
  
    image.onload = () => {
      canvas.width = image.width * scaleFactor;
      canvas.height = image.height * scaleFactor;
  
      context!.drawImage(image, 0, 0, canvas.width, canvas.height);
  
      const fontSize = 8 * scaleFactor; 
      context!.font = `${fontSize}px Arial`;
      context!.fillStyle = 'black';
      context!.textAlign = 'center';
  
      const textX = canvas.width / 2;
      const textY = canvas.height * 0.55; 
  
      context!.fillText(this.data.Code, textX, textY);
  
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = `${this.data.Code}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
  
    image.onerror = (err) => {
      console.error('Failed to load image', err);
    };
  }
  
  
  downloadRawData() {
    const dataStr = JSON.stringify(this.data.entry, null, 2);
    const blob = new Blob([dataStr], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'raw-data.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  downloadContent() {
    if (this.activeTab === 1) {
      this.downloadImage();
    } else if (this.activeTab === 2) {
      this.downloadRawData();
    } else {
      console.error('Unexpected active tab ID:', this.activeTab);
    }
  }

  closeView() {
    this.dialogRef.close();
  }

}
