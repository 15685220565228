
import { AfterViewInit, Component, EventEmitter, Output, ViewChild,SimpleChanges ,ChangeDetectorRef, ElementRef, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable} from 'rxjs';
import { ProjectService } from '../../shared/services/project.service';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginator,PageEvent } from '@angular/material/paginator';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ColorService } from '../../shared/services/theme.service'
import { MatIconModule } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import {LargeImageViewComponent} from '../../large-image-view/large-image-view.component'
import { RouterModule } from '@angular/router';
import { FormsModule, FormControl } from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { DatePipe } from '@angular/common';
import { MatSortModule, MatSort } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Subject } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';
import {MatButtonModule} from '@angular/material/button';
import {ConfirmationDialogComponent} from '../../confirmation-dialog/confirmation-dialog.component';
import {ConfirmationMessageComponent} from '../../../confirmation-message/confirmation-message.component';
import { Router } from '@angular/router';
import { AvatarModule } from 'ngx-avatars';
import { ReactiveFormsModule } from '@angular/forms'; 
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CodeLargeImageComponent } from 'src/code-large-image/code-large-image.component';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { StandardMessageDrawToolComponent } from 'src/app/standard-message-draw-tool/standard-message-draw-tool.component';


export interface Entry {
  id: string;
  consumer: {
    firstName: string;
    createdAt: string;
    lastName: string;  
  };
  moderationStatus: string;
 
}

@Component({
  selector: 'app-manage-winners',
  standalone: true,
  imports: [MatProgressSpinnerModule,ReactiveFormsModule, AvatarModule,MatButtonModule, MatMenuModule,MatToolbarModule,MatInputModule,MatSort,MatSortModule,DatePipe,MatMenuModule, MatSelectModule, MatFormFieldModule, RouterModule,CommonModule, MatTableModule, MatCheckboxModule, MatPaginatorModule, MatIconModule, FormsModule],
  templateUrl: './manage-winners.component.html',
  styleUrl: './manage-winners.component.scss'
})
export class ManageWinnersComponent implements AfterViewInit{
  @Output() close = new EventEmitter<void>();
  @Input() drawId: string;
  @Input() drawName: string;
  @Input() numberOfEntries: number;  
  @Input() drawConfig: any;  
  showManageWinners : boolean = true;
  @Input() token;
  @ViewChild('searchInput') input: ElementRef;
  exportListItem$: Observable<any[]>;
  @Output() exportSummaryEvent = new EventEmitter<void>();
  @Output() exportAllEvent = new EventEmitter<void>();
  currentPage = 1;
  selectedIds = new Set<string>();
  pageSize = 10;
  totalPages = 0;
  displayStartIndex: number = 1;
  displayEndIndex: number = 10;
  totalRecords: number = 0; 
  isDropdownOpen: boolean = false;
  moderationStatusOptions: string[] = [];
  showCheckbox = true; 
  searchControl = new FormControl();
  selectedModerationStatus: string | null = null;
  private unsubscribe$: Subject<void> = new Subject();
  selectedStatus: string;
  filteredEntries: Entry[] = [];
  searchText: string = '';
  checkbox: string = '';
  colorScheme: string;
  userId: string = '';
  entries: any[] = [];
  private _id;
  isDeselectAllChecked = false;
  selection = new SelectionModel<any>(true, []);
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = ['select', 'date', 'mobileNumber','image', 'moderationStatus' ,'prize', 'value' ];
  showSearchContainer: boolean = true;
  valueSelected: boolean = false;
  placeholderText: string = 'Select Status: ';
  @ViewChild(MatSort) sort: MatSort;
  isLoading = false; 
  @ViewChild(MatPaginator) paginator: MatPaginator;
entry: any;

private _paging = {
  skip:0,
  take:10,
  orderBy:'createdAt',
  orderDirection:'desc',
  moderationStatus: '' ,
  mobileNumber: ''   
};


  constructor(private http: HttpClient, 
             private projectService: ProjectService, 
             private route: ActivatedRoute,
             private colorService: ColorService,
             private dialog: MatDialog,
             private routerModule: RouterModule,
             private router: Router,
             private datePipe: DatePipe,
             private cdr: ChangeDetectorRef,
             private snackBar: MatSnackBar,
             private toastrService: ToastrService,
            ) {this.exportListItem$ = this.projectService.exportList$;  
              this._id = this.route.snapshot.paramMap.get('id');
             }

  ngOnInit(): void {
    console.log('managetoken');
    console.log(this.token, 'managetoken');
    this.route.params.subscribe(params => {
      const id = params['id']; 
      this.userId = id; 
      this.getProjectDetails(id);
      if (this.userId) {
        this.getDrawEntries(this.userId);
      }
    });
    // this.projectService.drawList(this.drawId);
    this.moderationStatuses();
    this.allDraws();
  }

  allDraws() {
    return this.http.get<any>(`${environment.endpoints.api}projects/${this._id}/draws?token=${this.token}`)
    .pipe(
    )
    .subscribe({
        next: (response: any) => {
        //  console.log("All Draw Responseeeeeeeeee:", response) // <--------
        },
      }
    );
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.sort.active = 'date';  
    this.sort.direction = 'desc';
    
    if (this.input && this.input.nativeElement) {
      fromEvent<KeyboardEvent>(this.input.nativeElement, 'keyup')
        .pipe(
          map(event => (event.target as HTMLInputElement).value),
          debounceTime(250),
          distinctUntilChanged(), 
          tap((value: string) => { 
            console.log(value); 
            this.searchText = value; 
            this.applyFilters(); 
          })
        )
        .subscribe();
    }
  }

  refreshComponent() {
    this.cdr.detectChanges();
    
  }


  getDrawEntries(id: string): void {
    this.isLoading = true; 
    const params = {
      ...this._paging,
      mobileNumber: this.searchText,
      moderationStatus: this.selectedModerationStatus
  };
    this.projectService.getDrawEntries(this.drawId, params, this.token).subscribe({
      
      next: (data) => {
        console.log('API Response1111111111111111:', data);
        console.log(this._id,'ID');
        if (data.pageCount === undefined || data.pageSize === undefined) {
          console.error('API response does not contain pageCount or pageSize');
          return;
        }
        this.totalRecords = (data.pageCount - 1) * data.pageSize + data.items.length;
        console.log('Total Records:', this.totalRecords); 
        this.dataSource.data = this.entries = data.items; 
        this.totalPages = data.pageCount; 
        // console.log('Entries:', this.entries); 
        // console.log('DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD rID',this.drawId)
        // console.log('UserID',this.userId)
        console.log('Entries:', this.entries); // <--------
        this.entries.forEach(entry => {
          // console.log('items DRARARA',entry)
          // console.log('itemsID',entry.id)
          // console.log('reward name',entry.id);
          // console.log('voucher',entry.reward.value);
          // console.log('moderation',entry.moderationStatus);
          // console.log('entry status',entry.entryStatus);
          // console.log('Entry Type:', entry.payload.BankName);
          this.isLoading = false; 
        });
        this.dataSource.data.forEach(row => {         
          if (this.selectedIds.has(row.id)) {
            console.log(row.id,"Row ID")
            this.selection.select(row);
          } else {
            this.selection.deselect(row);
          }
        });
        this.updateDisplayedEntries();
      },
      error: (error) => {
        console.error('Error fetching project entries:', error);
        this.isLoading = false; 
      }
    });
  }

  toggleSelection(row: any) {
  if (this.selection.isSelected(row)) {
    this.selectedIds.delete(row.id);
  } else {
    this.selectedIds.add(row.id);
  }
  this.selection.toggle(row);
  this.updateDeselectAllCheckbox();
}


  getProjectDetails(id: string): void {
    this.colorService.getProjectById(id, this.token).subscribe(
      (projectDetails) => {
        console.log('Project details:', projectDetails);
        this.colorScheme = projectDetails.colorScheme;
      },
      (error) => {
        console.error('Error fetching project details:', error);
        console.log("Error", error);
      }
    );
  }


  masterToggle() {
    if (this.isAllSelected()) {
      this.selectedIds.clear();
      this.selection.clear();
    } else {
      this.dataSource.data.forEach(row => {
      if (row.moderationStatus !== 'blacklisted' ) {
        this.selectedIds.add(row.id);
        this.selection.select(row);
      }
      });
    }
    this.updateDeselectAllCheckbox();
  }
  
  
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.filter(row => row.moderationStatus !== 'blacklisted').length;
    return numSelected === numRows;
  }
  


  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    const startIndex = event.pageIndex * event.pageSize;
    this._paging.skip = startIndex;
    this._paging.take = event.pageSize;
    this.getDrawEntries(this.userId);
  }
  
  
  showLargeImage(
    imageUrl: string, 
    firstName: string, 
    createdAt: string, 
    mobileNumber: number, 
    moderationStatus: string, 
    voucher: number,
    name: string, 
    value: number,
    drawId: string,  
    entryStatus: string
): void {
    console.log('Entry ID received:', drawId);  
    const entry = this.entries.find(item => item.id === drawId);

    if (!entry) {
        console.error(`Entry not found for id: ${drawId}`);
        return;
    }

    console.log('Entry found:', entry);

    const dialogRef = this.dialog.open(LargeImageViewComponent, {
        data: {
            imageUrl: imageUrl,
            firstName: firstName,
            createdAt: createdAt,
            mobileNumber: mobileNumber,
            voucher: voucher,
            jsonResponse: entry,
            name: name,
            value: value,
            entryStatus: entryStatus,
            moderationStatus: moderationStatus,
            consumerId: drawId,
            entry: entry ,
            drawConfig: this.drawConfig 
        }
    });

    dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
    });
}




  CodeImage(Code: string,Status: string,BankName: string,firstName:string, createdAt: string, mobileNumber: string, moderationStatus: string, entryId: string,): void {
    console.log('Code Entry ID received:', entryId);  
    const entry = this.entries.find(item => item.id === entryId);

    if (!entry) {
        console.error(`Code Entry not found for id: ${entryId}`);
        return;
    }
    console.log('Code Entry found:', entry);
    const dialogRef = this.dialog.open(CodeLargeImageComponent, {
      data: { 
        firstName: firstName,
        createdAt: createdAt,
        mobileNumber:mobileNumber,
        moderationStatus,
        BankName:BankName,
        Status:Status,
        Code:Code,
        entry: entry 
       }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  

  toggleRowSelection(event: MouseEvent, entry: any): void {
    const target = event.target as HTMLElement;
    const isCheckbox = target.tagName === 'MAT-CHECKBOX';
    if (!isCheckbox) {
      this.selection.toggle(entry);
    }
  }

  applyFilters() {
    this.searchText = this.input.nativeElement.value;
    if (this.selectedModerationStatus === 'all') {
      this._paging.moderationStatus = '';
    } else {
      this._paging.moderationStatus = this.selectedModerationStatus || '';
    }
    this._paging.mobileNumber = this.searchText;
    this._paging.skip = 0;
    this.getDrawEntries(this.userId);
  }
  

  
  selectAll(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.dataSource.data.forEach(row => this.selection.select(row));
    } else {
      this.selection.clear();
    }
  }

  
  async confirmUpdate() {
    console.log("in ConfirmUpdate", this.selection.selected);
  
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: {
        selectedRecords: this.selection.selected,
        updatedStatus: this.selectedStatus
      }
    });
  
    console.log("in ConfirmUpdate down", this.selection.selected);
  
    try {
      const result = await dialogRef.afterClosed().toPromise();
      if (result === 'confirm') {
        this.isLoading = true;
        await this.updateModerationStatus(this.selection.selected, this.selectedStatus);
        this.UpdateConfirmationMessage(this.selection.selected);
        await this.refreshComponent();
      }
    } catch (error) {
      this.showErrorMessage('Error occurred: ' + error);
    } finally {
      this.isLoading = false;
    }
  }
  
  
  UpdateConfirmationMessage(selectedRecords: any[]) {
    const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
      width: '400px',
      height: '200px',
      data: {
        selectedRecords: selectedRecords,
        updatedStatus: this.selectedStatus
      }
    });
  
    dialogRef.afterClosed().subscribe(() => {
      this.isLoading = false; 
    });
    this.selection.clear();
  }

  confirmation() {
    const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
      width: '400px',
      height: '200px',
      data: {
        selectedRecords: this.selection.selected,
        updatedStatus: this.selectedStatus
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'OK') {
        
      }
    });
  }
  
  
  
  cancelUpdate() {
    this.selection.clear();
  }
  
  updateModerationStatus(records: any[], status: string): Promise<void> {
    const body = {
      status: status,
      entries: records.map(record => record.id)  
    };
  
    return new Promise((resolve, reject) => {
      this.http.patch(`${environment.endpoints.api}projects/${this.userId}/entries/moderate?token=${this.token}`, body)
        .subscribe({
          next: (response) => {
            console.log('Status update successful:', response);
            this.getDrawEntries(this.userId);
            resolve(); 
          },
          error: (error) => {
            console.error('Failed to update statuses:', error);
            reject(error); 
          }
        });
    });
  }
  
  
  onStatusSelect(event: any): void {
    this.valueSelected = true;
  }
  // isValidSelectedModerationStatus(): boolean {
  //   return this.moderationStatusOptions.includes(this.selectedModerationStatus);
  // }

  formatDate(dateString: string | null): string {
    if (!dateString) {
      return 'No Date Provided'; 
    }
    const formattedDate = this.datePipe.transform(dateString, 'dd MMM, yyyy hh:mm a');
    return formattedDate || 'Invalid Date'; 
  }

  exportSummary() {
    console.log('Exporting Summary');
    this.exportSummaryEvent.emit();
  }


  exportAll() {
    console.log('Exporting All Data');
    this.exportAllEvent.emit();
  }
//   exportSelectedEntries() {
//     const selectedEntries = this.selection.selected; // This fetches all selected items
//     this.downloadExport();
// }
exportAllEntries() {
  this.downloadExport(this.entries); 
}

get hasEntries(): boolean {
  return this.entries && this.entries.length > 0;
}

download() {
  if (!this.hasEntries) {
    this.toastrService.info('No winners for this draw', undefined, { timeOut: 3000, positionClass: 'toast-bottom-right'});
    return;
  }

  // Always export all entries, not just selected or visible ones
  this.projectService.getDrawEntries(this.drawId, {skip: 0, take: this.totalRecords, token: this.token} , this.token)
  .subscribe({
    next: (data) => {
      if (data && data.items.length > 0) {
        let _config = {
          "type": "draws",
          "configuration": {
            "draw": this.drawId,
            "entries": data.items.length,
            "config": this.drawConfig
          },
          "name": this.drawName,
          "description": "Export of draw "
        };
        
        console.log(this.userId, 'Exporting all entries:', _config.configuration.entries);
        this.projectService.export(_config, this.userId, this.token);
        this.showExportMessage();
      } else {
        this.toastrService.info('No winners to export', undefined, { timeOut: 3000, positionClass: 'toast-bottom-right'});
      }
    },
    error: (err) => {
      console.error('Failed to fetch entries for exporting:', err);
      this.toastrService.error('Failed to fetch entries for exporting', undefined, { timeOut: 3000, positionClass: 'toast-bottom-right'});
    }
  });
  console.log(this.token,'TTTTTTTTTTTTTTTTTT')
}



downloadExport(item,){
  this.projectService.downloadExport(item,this.token)
}

ngOnChanges(changes: SimpleChanges): void {
  if (changes['selectedModerationStatus'] && !changes['selectedModerationStatus'].firstChange) {
    this.updatePlaceholder();
  }
  if (changes['selection'] && !changes['selection'].currentValue.hasValue()) {
    this.isDeselectAllChecked = true;
  }
}


updatePlaceholder(): void {
  this.placeholderText = this.selectedModerationStatus ? `Select Status: ${this.selectedModerationStatus}` : 'Select Status';
  this.cdr.detectChanges(); 
}

fetchStatuses() {
  this.projectService.fetchModerationStatuses(this.token);
}

moderationStatuses(){
  this.projectService.moderationStatuses$.pipe(
    takeUntil(this.unsubscribe$)
  ).subscribe(
    (statuses: string[]) => {
      this.moderationStatusOptions = statuses;
      console.log('Received statuses:', statuses);
    },
    error => console.error('Error receiving statuses', error)
  );
  this.fetchStatuses();
}


updateDisplayedEntries() {
  const startIndex = (this.currentPage - 1) * this.pageSize;
  const endIndex = Math.min(startIndex + this.pageSize, this.totalRecords);
  this.displayStartIndex = startIndex + 1;
  this.displayEndIndex = endIndex;
  this.dataSource.data = this.entries.slice(startIndex, endIndex);
}

viewEntry(entry: any): void {
  this.projectService.setEntryData(entry);
  this.router.navigate(['/entry-data']);
}

deselectAll(event: Event): void {
  const checkbox = event.target as HTMLInputElement;
  if (!checkbox.checked) {
    this.selection.clear();
    this.selectedIds.clear();
  }else{
    
  }
  this.isDeselectAllChecked = true;  
}

updateDeselectAllCheckbox() {
  this.isDeselectAllChecked = this.selection.hasValue();
}

showExportMessage(): void {
  this.dialog.open(StandardMessageDrawToolComponent, {
    data: { message: 'Export initiated!' }
  });
}

showErrorMessage(message: string) {
  this.snackBar.open(message, 'Close', {
    duration: 3000,
    verticalPosition: 'top', 
    horizontalPosition: 'end', 
  });
}

isImage(url: string): boolean {
  return /\.(jpeg|jpg|gif|png)$/.test(url);
}

isVideo(url: string): boolean {
  return /\.(mp4|mov)$/.test(url);
}

isAudio(url: string): boolean {
  return /\.(wav|mp3|ogg)$/.test(url);
}

goBack(): void {
  this.close.emit(); 
}



// downloadSelectedEntries() {
//   // Collect the selected or visible entries to export
//   const selectedEntries = this.selection.selected.length > 0 
//     ? this.selection.selected // Export only selected entries if any are selected
//     : this.entries; // Otherwise, export all visible entries

//   let exportConfig = {
//     "type": "draws",
//     "configuration": selectedEntries,
//     "name": "Selected Draw Entries",
//     "description": "Export of selected or visible draw entries"
//   };

//   // Log or Debug the selected entries
//   console.log('Exporting entries for draw:', selectedEntries);

//   // Pass the selected entries to the service for export
//   this.projectService.exporT(exportConfig, this.userId).subscribe(
//     response => {
//       console.log('Export Entries:', selectedEntries);
//       console.log('Export successful:', response);
//       this.toastrService.success('Export initiated successfully');
//     },
//     error => {
//       console.error('Export failed:', error);
//       this.toastrService.error('Failed to initiate export');
//     }
//   );
//  }
}